@import url('./VARIABLES.css');

/* Custom Radio Button */

.radio {
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.radio input[type=radio] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.radio .controls-label {
    -ms-flex-align: baseline;
    align-items: baseline;
    color: var(--PrimaryTextColor);
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding-left: 16px;
    position: relative;
    text-align: left;
}

.radio input[type=radio]:checked+.controls-label:before {
    border: 1px solid var(--RadioButton__CheckedBorder);
}

.radio .controls-label:before {
    border-radius: 50%;
}

.controls-label:before {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 0;
    border: 1px solid var(--RadioButton__Border);
    content: '';
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex: 0 0 18px;
    flex: 0 0 18px;
    height: 18px;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 8px 0 -16px;
    max-width: 18px;
    transition: background .3s ease;
    width: 18px;
}

.radio input[type=radio]:checked + .controls-label:after {
    background: var(--RadioButton__CheckedBg);
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    width: 9px;
}

.radio input[type=radio]:disabled + .controls-label:before {
    background: var(--InputDisabled__Bg);
    border: var(--InputDisabled__Border);
    cursor: default;
}

div.radioButton,
.form-group .control-label.inline,
.radioButtonWrapper {
    display: inline;
}

.radioButton input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

.radioButton input[type="radio"]+label {
    margin: .5rem .5rem 1rem 0;
    padding: 6px 2rem 6px 2rem;
    border: 1px solid var(--RadioWithBg__Border);
    background-color: var(--RadioWithBg__Bg);
    color: var(--RadioWithBg__CheckedText);
    display: inline-block;
    text-align: center;
    border-radius: 4px;
}

.radioButton input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}

.radioButton input:checked+label,
.radioButton label.selected,
.radioButton input[type="radio"]+label:hover {
    color: var(--RadioWithBg__CheckedText);
    background-color: var(--RadioWithBg__CheckedBg);
    border-color: var(--RadioWithBg__CheckedBorder);
}

.radioButton:last-child input[type="radio"]+label,
.radioButton:nth-last-child(2) input[type="radio"]+label {
    margin-bottom: 0;
}

.radioButton:first-child input[type="radio"]+label,
.radioButton:nth-child(2) input[type="radio"]+label {
    margin-top: 0;
}

/* SquareRadio */

.squareRadioBtn input[type="radio"]:checked + label,
.squareRadioBtn label.selected,
.squareRadioBtn input[type="radio"] + label:hover,
.squareRadioBtn input[type="radio"] + label:focus,
.squareRadioBtn input[type="radio"] + label:active {
    color: #fff !important;
    background-color: var(--InputToggle__Color) !important;
    border-color: var(--InputToggle__Color) !important;
}

.squareRadioBtn input[type="radio"]+label {
    padding: 5px 12px 5px 12px;
    border: 1px solid var(--Input__Border);
    border-radius: var(--Input__BorderRadius);
    background-color: #fff;
    color: var(--Heading__Color);
    text-align: center;
}

.squareRadioBtn input[type="radio"] {
    display: none;
    width: 0px;
    height: 0px;
}


/* Check box */

.checkbox {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
}

.checkboxLabel {
    color: #001871;
    font-family: "aig_sanssourcepro";
    letter-spacing: 0.29px;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
    /* height: -webkit-fill-available; */
    align-items: center;
    color: var(--Input__LabelColor);
    text-wrap: wrap;
}

    .checkboxLabel:before {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-item-align: start;
        align-self: flex-start;
        background: #FFFFFF;
        border: 1px solid var(--Checkbox__Border);
        border-radius: var(--Btn__BorderRadius);
        content: '';
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex: 0 0 18px;
        flex: 0 0 18px;
        height: 18px;
        -ms-flex-pack: center;
        justify-content: center;
        max-width: 18px;
        width: 18px;
        height: 18px;
        margin: 0px;
        margin-right: 10px;
        align-items: center;
    }

.checkbox input[type=checkbox] {
    left: -9999px;
    opacity: 0;
    position: absolute;
}

.checkbox input[type=checkbox]:checked+.checkboxLabel:before {
    margin-top: 0;
    border: none;
    border-radius: 1px;
    /* background: #001871; */
    background-image: url('../assets/images/checkbox-selected.svg');
    background-position: center;
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.Lennox:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.lennox:before {
    background-image: url('../assets//images/Vendor/Lennox/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.Rheem:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.rheem:before {
    background-image: url('../assets//images/Vendor/Rheem/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.Carrier:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.carrier:before {
    background-image: url('../assets//images/Vendor/Carrier/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.Trane:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.trane:before {
    background-image: url('../assets//images/Vendor/Trane/checkbox-selected.svg');
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.GE:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.ge:before {
    background-image: url('../assets/images/Vendor/GE/checkbox-selected.svg');
    width: 24px;
    height: 24px;
}

.checkbox input[type=checkbox]:checked+.checkboxLabel.ICP:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.icp:before {
    background-image: url('../assets/images/Vendor/ICP/checkbox-selected-icp.svg');
}

/* Added due to ICP login form */
.checkbox input[type=checkbox]:checked+.checkboxLabel.ICP.invertedCheckboxICP:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.icp.invertedCheckboxICP:before {
    background-image: url('../assets/images/Vendor/ICP/inverted-checkbox-selected.svg');
}


.checkbox input[type=checkbox]:checked+.checkboxLabel.HomeDepot:before,
.checkbox input[type=checkbox]:checked+.checkboxLabel.homedepot:before {
    background-image: url('../assets/images/Vendor/HomeDepot/checkbox-selected-hd.svg');
}
.checkboxLabel.GE:before, .checkboxLabel.ge:before {
    background-image: url('../assets/images/Vendor/GE/checkbox.svg');
    max-width: 24px;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0;
    flex: 1 0 0;
}

.checkbox input[type=checkbox]:focus+.checkboxLabel:before {
    border-color: #00a4e4 !important;
    border-width: 2px;
}

.checkboxinput[type=checkbox]:hover+.checkboxLabel:before {
    border-color: #00a4e4 !important;
    border-width: 1px;
}

.checkbox input[type=checkbox]:checked+.scriptBox .checkboxLabel:before {
    margin-top: 3px;
}

.checkboxField {
    margin-left: .8rem;
}

.inputGroup {
    display: -ms-flexbox;
    position: relative;
    width: 100%;
}

.input-group-text {
    background-color: #fff;
    border: 1px solid var(--Color__Neutral3);
    padding: 6px 6px 6px 0;
}

.dateInput {
    /* max-width: 165px; */
    border-right: 0 !important;
    border-radius: 3px 0 0 3px !important;
}

.datePicker {
    min-width: 28px;
    border: 1px solid var(--Input__Border);
    border-left: 0;
    padding: 6px;
    height: 35px;
    border-radius: 0 3px 3px 0 !important;
    /* border-top-right-radius: 3px; */
    /* border-bottom-right-radius: 3px; */
}

.inputCalendar {
    fill: #0057B8;
    width: 19px;
    height: 19px;
}