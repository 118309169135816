/* Common SVG styles */
.st0{fill-rule:evenodd;clip-rule:evenodd;}
.amex-1{fill:#3177a4;}.amex-2{fill:#fff;}
.dis-1{fill:#f8f8f8;}.dis-2{fill:#e6863c;}.dis-3{fill:url(#radial-gradient);}.dis-4{fill:#051721;}.dis-5{fill:#221f20;}
.master-1{fill:#f8f8f8;}.master-2{fill:#f0b34e;}.master-2,.master-3,.master-4{fill-rule:evenodd;}.master-3{fill:#d83738;}.master-4{fill:#fff;}.master-5{fill:none;stroke:#fff;stroke-miterlimit:2.61;stroke-width:0.22px;}
.cls-1{fill:none;}.cls-2,.cls-3{fill:#b1c5d7;}.cls-2{fill-rule:evenodd;}.cls-4{isolation:isolate;}.cls-5{fill:#134ee6;}
.visa-1{fill:#f8f8f8;}.visa-2{fill:#2866ab;}.visa-3{fill:#eda74d;}
.checkboxBkg{fill-rule:evenodd;clip-rule:evenodd;fill:url(#UI-_x2F_-Checkbox-_x2F_-Default_1_);}.check{fill:#FFFFFF;}
.greenCheckmarkBkg{fill:#4ea54f;}
.coverageSt0{fill:#001871;}
.coverageSt1{enable-background:new;}
.coverageSt2{fill:#343741;}
.bkg{fill:#F6F9FB;}
.bkgOpacity{opacity:0.2;}
.ltBkgShield{fill-rule:evenodd;clip-rule:evenodd;fill:#E0E5EB;}
.dkBkgShield{fill-rule:evenodd;clip-rule:evenodd;fill:#BCC6D2;}

/* Client - AIG specific SVG styles */
.aigLogo {fill:#001871;}
.checkboxAccent{fill:#E70134;}
.checkboxPrimary{fill:#001871;}
.contactSecondaryBrand{fill:#1352de;}
.contactAccent1{fill:#CCE3FD;}
.contactAccent2{fill:#63ADFF;}
.shieldNeutral3{fill:#b9c6d4;}
.shieldBkg{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:#88E5AD;}
.shieldBorder{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:#001871;}
.clockNeutral3{fill:#b9c6d4;}
.clockOpacity{opacity:0.5859;}
.clockBackground{fill-rule:evenodd;clip-rule:evenodd;fill:#FFBF3F;}
.clockBorder{fill:#002554;}
.dollarBackground{fill-rule:evenodd;clip-rule:evenodd;fill:#FDCB90;}
.dollarOutline{fill:#001871;}
.emailSecondaryBrad{fill:#1352de;}
.emailAccent1{fill:#CCE3FD;}
.emailAccent2{fill:#63ADFF;}
.emailGreenCheckBkg{fill:#4ea54f;}
.exclamationSecondaryBrand{fill:#1352de;}
.exclamationAccent1{fill:#CCE3FD;}
.graphPrimaryClip{fill-rule:evenodd;clip-rule:evenodd;fill:#001871;}
.graphPrimary{fill:#001871;}
.graphAccent{fill:#E70033;}
.lockPrimary{fill:#001871;}
.lockAccent{fill:#E70033;}
.profileSecondaryBrad{fill:#1352de;}
.profileAccent1{fill:#CCE3FD;}
.profileAccent2{fill:#63ADFF;}
.ratingsPrimary{fill:#001871;}
.ratingsAccent{fill:#E70134;}
.reportsBackground{fill-rule:evenodd;clip-rule:evenodd;fill:#88E5AD;}
.reportsOutline{fill:#001871;}
.resourcesBackground{fill-rule:evenodd;clip-rule:evenodd;fill:#BC95E8;}
.resourcesOutline{fill:#001871;}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:red;}
.revenueSecondaryBrand{fill:#1352de;}
.revenueAccent1{fill:#CCE3FD;}
.revenueAccent2{fill:#63ADFF;}
.saleNeutral3{fill:#b9c6d4;}
.sheildNeutral3{fill:#b9c6d4;}
.successTransparent{fill:none;}
.successSecondaryBrand{fill:#1352de;}
.successAccent1{fill:#CCE3FD;}
.successAccent2{fill:#63ADFF;}
.successGreenCheckBkg{fill:#4ea54f;}
.toolsCircleBkg{fill-rule:evenodd;clip-rule:evenodd;fill:#55BEBE;}
.toolsOutline{fill:#001871;} 
.uploadAccent1{fill-rule:evenodd;clip-rule:evenodd;fill:#F6F9FB;}
.uploadSecondaryBrand{fill-rule:evenodd;clip-rule:evenodd;fill:#1352de;}

/* Client - Carrier SVG styles */
.rheem{fill:#093266;}
.checkboxBkgCSC{fill:#AF2025;}
.checkCSC{fill:#FFFFFF;}
.checkboxBorderCSC{fill:#b1b1b1;}
.checkboxPrimaryCSC{fill:#224694;}
.checkboxAccentCSC{fill:#AF2025;}
.climateShield1CSC{fill:#495558;}.climateShield2CSC{fill:url(#a);}.climateShield3CSC{fill:#bbbcbe;}.climateShield4CSC{fill:#fff;opacity:0.41;}.climateShield5CSC{fill:url(#c);}.climateShield6CSC{fill:#b4121b;}.climateShield7CSC{fill:url(#b);}.climateShield8CSC{fill:#1c3e93;}
.contactPrimaryBrandCSC{fill:#224694;}
.contactAccent1CSC{fill:#b1b1b1;}
.contactAccent2CSC{fill:#b1b1b1;}
.shieldNeutral4CSC{fill:#b1b1b1;}
.shieldBkgCSC{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.shieldBorderCSC{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#b1b1b1;}
.clockNeutral4CSC{fill:#b1b1b1;}
.clockOpacityCSC{opacity:100;}
.clockBackgroundCSC{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.clockBorderCSC{fill:#b1b1b1;}
.dollarBackgroundCSC{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.dollarOutlineCSC{fill:#b1b1b1;}
.circleOutlineCSC{fill:#224694;}
.dollarBackgroundPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.dollarOutlinePP{fill:#807F83;}
.circleOutlinePP{fill:#093266;}
.emailPrimaryBradCSC{fill:#224694;}
.emailAccent1CSC{fill:#b1b1b1;}
.emailAccent2CSC{fill:#b1b1b1;}
.emailGreenCheckBkgCSC{fill:#4ea54f;}
.exclamationPrimaryBrandCSC{fill:#224694;}
.exclamationAccent1CSC{fill:#b1b1b1;}
.graphPrimaryClipCSC{fill-rule:evenodd;clip-rule:evenodd;fill:#224694;}
.graphPrimaryCSC{fill:#224694;}
.graphAccentCSC{fill:#AF2025;}
.lockPrimaryCSC{fill:#224694;}
.lockAccentCSC{fill:#AF2025;}
.profileAlertPrimaryBrandCSC{fill:#224694;}
.profileAlertAccent1CSC{fill:#b1b1b1;}
.profileAlertAccent2CSC{fill:#b1b1b1;}
.profileAlertRedCSC{fill:#d01b00;}
.profilePrimaryBradCSC{fill:#224694;}
.profileAccent1CSC{fill:#b1b1b1;}
.profileAccent2CSC{fill:#b1b1b1;}
.ratingsPrimaryCSC{fill:#224694;}
.ratingsAccentCSC{fill:#AF2025;}
.reportsBackgroundCSC{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.reportsOutlineCSC{fill:#b1b1b1;}
.circleOutlineCSC{fill:#224694;}
.resourcesBackgroundCSC{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.resourcesOutlineCSC{fill:#b1b1b1;}
.circleOutlineCSC{fill:#224694;}
.revenuePrimaryBrandCSC{fill:#224694;}
.revenueAccent1CSC{fill:#b1b1b1;}
.revenueAccent2CSC{fill:#b1b1b1;}
.sheildNeutral4CSC{fill:#b1b1b1;}
.successTransparentCSC{fill:none;}
.successPrimaryBrandCSC{fill:#224694;}
.successAccent1CSC{fill:#b1b1b1;}
.successAccent2CSC{fill:#b1b1b1}
.successGreenCheckBkgCSC{fill:#4ea54f;}
.toolsCircleBkgCSC{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.toolsOutlineCSC{fill:#b1b1b1;}
.circleOutlineCSC{fill:#224694;}
.uploadAccent1CSC{fill-rule:evenodd;clip-rule:evenodd;fill:#EFEFEF;}
.uploadPrimaryBrandCSC{fill-rule:evenodd;clip-rule:evenodd;fill:#224694;}

/* Client - GE specific SVG styles */
.checkbox1GE{fill:#FFFFFF;}
.checkbox2GE{fill:#B9C6D4;}
.checkbox3GE{fill:#003B71;}
.checkboxBorderGE{fill:#B9C6D4;}
.shieldPrimaryBradCSC{fill:#00486F;}
.shieldSecondaryBradCSC{fill:#90B4DF;}
.contactPrimaryBrandGE{fill:#1352DE;}
.contactAccent1GE{fill:#DEE9F6;}
.contactAccent2GE{fill:#B9C6D4;}
.shieldNeutral4GE{opacity:0.2;fill:#B9C6D4;}
.shieldBkgGE{opacity:0.2;fill-rule:evenodd;clip-rule:evenodd;fill:#88E5AD;}
.shieldBorderGE{opacity:0.2;fill-rule:evenodd;clip-rule:evenodd;fill:#B9C6D4;}
.shieldNeutralActive4GE{fill:#B9C6D4;}
.shieldBkgActiveGE{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:#88E5AD;}
.shieldBorderActiveGE{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#B9C6D4;}
.clockNeutral4GE{fill:#B9C6D4;}
.clockOpacityGE{opacity:100;}
.clockBackgroundGE{fill-rule:evenodd;clip-rule:evenodd;fill:#FFBF3F;}
.clockBorderGE{fill:#B9C6D4;}
.customerCircle1GE{fill:#F1BA55;}
.customerCircle2GE{fill:#FFFFFF;}
.customerCircle3GE{fill:#003B71;}
.dollarBackgroundGE{fill:#92B3E0;}
.dollarClipGE{fill:none;}
.circleOutlineGE{fill:#003B71;}
.emailPrimaryBrandGE{fill:#1352DE;}
.emailAccent1GE{fill:#DEE9F6;}
.emailAccent2GE{fill:#B9C6D4;}
.emailGreenCheckBkgGE{fill:#4ea54f;}
.exclamationPrimaryBrandGE{fill:#1352DE;}
.exclamationAccent1CSC{fill:#DEE9F6;}
.revenueAccentGE{fill:#F1BA55;}
.revenuePrimaryGE{fill:#003B71;}
.handshakeCircleBkg{fill:#F1BA55;}
.handshakeIconOutline{fill:#003B71;}
.paperCircleBkg{fill:#F1BA55;}
.paperIconOutline{fill:#003B71;}
.paymentNeutral4GE{opacity:0.2; fill:#B9C6D4;}
.paymentBackgroundGE{opacity:0.2; fill:#FFBF3F;}
.peopleCircleBkg{fill:#F1BA55;}
.peopleIconOutline{fill:#003B71;}
.peoplePrimaryGE{fill:#003B71;}
.peopleAccentGE{fill:#F1BA55;}
.profileAlertPrimaryBrandGE{fill:#1352DE;}
.profileAlertAccent1GE{fill:#DEE9F6;}
.profileAlertAccent2GE{fill:#B9C6D4;}
.profileAlertRedGE{fill:#d01b00;}
.profileCircleBkg{fill:#F1BA55;}
.profileIconOutline{fill:#003B71;}  
.profilePrimaryBrandGE{fill:#1352DE;}
.profileAccent1GE{fill:#DEE9F6;}
.profileAccent2GE{fill:#B9C6D4;}
.ratingsPrimaryGE{fill:#003B71;}
.ratingsAccentGE{fill:#F1BA55;}
.reportsBackgroundGE{fill:#92B3E0;}
.reportsClipGE{fill:none;}
.circleOutlineGE{fill:#003B71;}
.resourcesCircleBkgGE{fill:#92B3E0;}
.resourcesClipGE{fill:none;}
.circleOutlineGE{fill:#003B71;}
.revenuePrimaryBrandGE{fill:#1352DE;}
.revenueAccent1GE{fill:#DEE9F6;}
.revenueAccent2GE{fill:#B9C6D4;}
.sheildNeutralHome4GE{opacity:0.2; fill:#B9C6D4;}
.sheildNeutral4GE{fill:#B9C6D4;}
.shieldCheckPrimaryGE{fill:#003B71;}
.shieldCheckAccentGE{fill:#F1BA55;}
.successTransparentGE{fill:none;}
.successPrimaryBrandGE{fill:#1352DE;}
.successAccent1GE{fill:#DEE9F6;}
.successAccent2GE{fill:#B9C6D4}
.successGreenCheckBkgGE{fill:#4ea54f;}
.circleBkgGE{fill:#92B3E0;}
.circleToolsClipGE{fill:none;}
.circleOutlineGE{fill:#003B71;}
.uploadAccent1GE{fill-rule:evenodd;clip-rule:evenodd;fill:#F6F9FB;}
.uploadPrimaryBrandGE{fill-rule:evenodd;clip-rule:evenodd;fill:#1352DE;}
.verifyCircleBkg{fill:#F1BA55;}
.verifyIconOutline{fill:#003B71;}
.verifyCircleGreen{fill:#13AA41;}
.verifyCheckmark{fill:#FFFFFF;}

/* Client - Lennox specific SVG styles */
.checkboxBkgCS{fill:#C60C35;}
.checkCS{fill:#FFFFFF;}
.checkboxPrimaryCS{fill:#666666;}
.checkboxAccentCS{fill:#c60c35;}
.comfortShield1Lennox{fill:#d8d6d5;}.comfortShield2Lennox{fill:#fff;}.comfortShield3Lennox{fill:#b4002d;}
.contactSecondaryBrandCS{fill:#666666;}
.contactAccent1CS{fill:#dcdcdc;}
.contactAccent2CS{fill:#b1b1b1;}
.shieldNeutral3CS{fill:#dcdcdc;}
.shieldBkgCS{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.shieldBorderCS{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#dcdcdc;}
.clockNeutral3CS{fill:#dcdcdc;}
.clockOpacityCS{opacity:100;}
.clockBackgroundCS{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.clockBorderCS{fill:#dcdcdc;}
.dollarBackgroundCS{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.dollarOutlineCS{fill:#666666;}
.emailSecondaryBradCS{fill:#666666;}
.emailAccent1CS{fill:#dcdcdc;}
.emailAccent2CS{fill:#b1b1b1;}
.emailGreenCheckBkgCS{fill:#4ea54f;}
.exclamationSecondaryBrandCS{fill:#666666;}
.exclamationAccent1CS{fill:#dcdcdc;}
.graphPrimaryClipCS{fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}
.graphPrimaryCS{fill:#666666;}
.graphAccentCS{fill:#c60c35;}
.LennoxSt0{filter:url(#f);}
.LennoxSt1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.LennoxSt2{mask:url(#c);fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}
.LennoxSt3{filter:url(#e);}
.LennoxSt4{mask:url(#b);fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}
.LennoxSt5{filter:url(#d);}
.LennoxSt6{mask:url(#a);fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}
.LennoxSt7{fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}
.lockPrimaryCS{fill:#666666;}
.lockAccentCS{fill:#c60c35;}
.profileAlertSecondaryBrandCS{fill:#666666;}
.profileAlertAccent1CS{fill:#dcdcdc;}
.profileAlertAccent2CS{fill:#b1b1b1;}
.profileAlertRedCS{fill:#d01b00;}
.profileAccent2CS{fill:#b1b1b1;}
.profileSecondaryBradCS{fill:#666666;}
.profileAccent1CS{fill:#dcdcdc;}
.profileAccent2CS{fill:#b1b1b1;}
.ratingsPrimaryCS{fill:#666666;}
.ratingsAccentCS{fill:#c60c35;}
.reportsBackgroundCS{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.reportsOutlineCS{fill:#666666;}
.resourcesBackgroundCS{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.resourcesOutlineCS{fill:#666666;}
.revenueSecondaryBrandCS{fill:#666666;}
.revenueAccent1CS{fill:#dcdcdc;}
.revenueAccent2CS{fill:#b1b1b1;}
.saleNeutral3CS{fill:#dcdcdc;}
.sheildNeutral3CS{fill:#dcdcdc;}
.successTransparentCS{fill:none;}
.successSecondaryBrandCS{fill:#666666;}
.successAccent1CS{fill:#dcdcdc;}
.successAccent2CS{fill:#b1b1b1}
.successGreenCheckBkgCS{fill:#4ea54f;}
.toolsCircleBkgCS{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.toolsOutlineCS{fill:#666666;}
.uploadAccent1CS{fill-rule:evenodd;clip-rule:evenodd;fill:#EFEFEF;}
.uploadPrimaryBrandCS{fill-rule:evenodd;clip-rule:evenodd;fill:#666666;}

/* Client - Rheem specific SVG styles */
.coveragePrimaryBrandPP{fill:#093266;}
.coverageParentPP{enable-background:new    ;}
.coverageNeutral5PP{fill:#616160;}
.rheem{fill:#093266;}
.checkboxBkgPP{fill:#093266;}
.checkPP{fill:#FFFFFF;}
.checkboxBorderPP{fill:#807F83;}
.checkboxPrimaryPP{fill:#093266;}
.checkboxAccentPP{fill:#807F83;}
.contactSecondaryBrandPP{fill:#093266;}
.contactAccent1PP{fill:#dcdcdc;}
.contactAccent2PP{fill:#dcdcdc;}
.shieldNeutral3PP{fill:#dcdcdc;}
.shieldBkgPP{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.shieldBorderPP{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#dcdcdc;}
.clockNeutral3PP{fill:#dcdcdc;}
.clockOpacityPP{opacity:100;}
.clockBackgroundPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.clockBorderPP{fill:#dcdcdc;}
.dollarBackgroundPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.dollarOutlinePP{fill:#807F83;}
.circleOutlinePP{fill:#093266;}
.emailSecondaryBradPP{fill:#093266;}
.emailAccent1PP{fill:#dcdcdc;}
.emailAccent2PP{fill:#dcdcdc;}
.emailGreenCheckBkgPP{fill:#4ea54f;}
.exclamationSecondaryBrandPP{fill:#093266;}
.exclamationAccent1PP{fill:#dcdcdc;}
.graphPrimaryClipPP{fill-rule:evenodd;clip-rule:evenodd;fill:#093266;}
.graphPrimaryPP{fill:#093266;}
.graphAccentPP{fill:#807F83;}
.lockPrimaryPP{fill:#093266;}
.lockAccentPP{fill:#807F83;}
.profileAlertAccent1PP{fill:#dcdcdc;}
.profileAlertSecondaryBrandPP{fill:#093266;}
.profileAlertAccent2PP{fill:#dcdcdc;}
.profileAlertRedPP{fill:#d01b00;}
.profileSecondaryBradPP{fill:#093266;}
.profileAccent1PP{fill:#dcdcdc;}
.profileAccent2PP{fill:#dcdcdc;}
.ratingsPrimaryPP{fill:#093266;}
.ratingsAccentPP{fill:#807F83;}
.reportsBackgroundPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.reportsOutlinePP{fill:#807F83;}
.circleOutlinePP{fill:#093266;}
.resourcesBackgroundPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.resourcesOutlinePP{fill:#807F83;}
.circleOutlinePP{fill:#093266;}
.revenueSecondaryBrandPP{fill:#093266;}
.revenueAccent1PP{fill:#dcdcdc;}
.revenueAccent2PP{fill:#dcdcdc;}
.sheildNeutral3PP{fill:#dcdcdc;}
.successTransparentPP{fill:none;}
.successSecondaryBrandPP{fill:#093266;}
.successAccent1PP{fill:#dcdcdc;}
.successAccent2PP{fill:#dcdcdc}
.successGreenCheckBkgPP{fill:#4ea54f;}
.toolsCircleBkgPP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.toolsOutlinePP{fill:#807F83;}
.circleOutlinePP{fill:#093266;}
.uploadAccent1PP{fill-rule:evenodd;clip-rule:evenodd;fill:#EFEFEF;}
.uploadPrimaryBrandPP{fill-rule:evenodd;clip-rule:evenodd;fill:#093266;}

/* Trane specific SVG styles */
.checkboxBkgTR{fill:#F26649;}
.checkTR{fill:#FFFFFF;}
.checkboxBorderTR{fill:#b1b1b1;}
.profilePrimaryBrandTR{fill:#0067B1;}
.profileAccent1TR{fill:#b1b1b1;}
.profileAccent2TR{fill:#b1b1b1;}
.revenuePrimaryBrandTR{fill:#0067B1;}
.revenueAccent1TR{fill:#b1b1b1;}
.revenueAccent2TR{fill:#b1b1b1;}

/* ICP specific SVG styles */
.dollarBackgroundICP{fill-rule:evenodd;clip-rule:evenodd;fill:#E7E7E7;}
.dollarOutlineICP{fill:#2E8B85;}
.circleOutlineICP{fill:#2E8B85;}
.reportsBackgroundICP{fill:#E7E7E7;}
.reportsClipICP{fill:none;}
.circleOutlineICP{fill:#2E8B85;}
.resourcesCircleBkgICP{fill:#E7E7E7;}
.resourcesClipICP{fill:none;}
.circleOutlineICP{fill:#2E8B85;}
.circleBkgICP{fill:#E7E7E7;}
.circleToolsClipICP{fill:none;}
.circleOutlineICP{fill:#2E8B85;}
.uploadAccent1ICP{fill-rule:evenodd;clip-rule:evenodd;fill:#E7E7E7;}
.uploadPrimaryBrandICP{fill-rule:evenodd;clip-rule:evenodd;fill:#D65241;}
.lockPrimaryICP{fill:#2E8B85;}
.lockAccentICP{fill:#D65241;}
.peoplePrimaryICP{fill:#2E8B85;}
.peopleSecondaryBrandICP{fill:#D65241;}
.exclamationSecondaryBrandICP{fill:#2E8B85;}
.exclamationAccent1ICP{fill:#8CBAB8;}
.enrollmentPrimaryBrandICP{fill: #2e8b85;}
.enrollmentGreenCheckBkgICP{fill: #13aa41;}
.successTransparentICP{fill:none;}
.successSecondaryBrandICP{fill:#2E8B85;}
.successAccent1ICP{fill:#8CBAB8;}
.successAccent2ICP{fill:#8CBAB8}
.successGreenCheckBkgICP{fill:#4ea54f;}
.shieldCheckPrimaryICP{fill:#2E8B85;}
.shieldCheckSecondaryBrandICP{fill:#D65241;}
.sheildNeutral3ICP{fill:#dcdcdc;}
.revenueSecondaryBrandPP{fill:#2E8B85;}
.revenueAccent1PP{fill:#8CBAB8;}
.revenueAccent2PP{fill:#8CBAB8;}
.ratingsPrimaryICP{fill:#2E8B85;}
.ratingsSecondaryBrandICP{fill:#D65241;}
.profileSecondaryBradICP{fill:#2E8B85;}
.profileAccent1ICP{fill:#8CBAB8;}
.profileAccent2ICP{fill:#8CBAB8;}
.profileAlertAccent1ICP{fill:#8CBAB8;}
.profileAlertSecondaryBrandICP{fill:#2E8B85;}
.profileAlertAccent2ICP{fill:#8CBAB8;}
.profileAlertRedICP{fill:#d01b00;}
.loginPrimaryBrandICP {fill:#2e8b85; fill-rule:evenodd;}
.revenueSecondaryBrandICP{fill:#D65241;}
.revenuePrimaryICP{fill:#2E8B85;}
.enrollICP{fill-rule: evenodd;fill: #fff;}
.emailSecondaryBradICP{fill:#2E8B85;}
.emailAccent1ICP{fill:#8CBAB8;}
.emailAccent2ICP{fill:#8CBAB8;}
.emailGreenCheckBkgICP{fill:#4ea54f;}
.DealerPrimaryBrandICP {fill:#2e8b85;}
.clockNeutral3ICP{fill:#dcdcdc;}
.clockOpacityICP{opacity:100;}
.clockBackgroundICP{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.clockBorderICP{fill:#dcdcdc;}
.shieldNeutral3ICP{fill:#dcdcdc;}
.shieldBkgICP{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.shieldBorderICP{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#dcdcdc;}
.contactSecondaryBrandICP{fill:#2E8B85;}
.contactAccent1ICP{fill:#8CBAB8;}
.contactAccent2ICP{fill:#8CBAB8;}
.logoIconWhiteICP {fill: #fff;}
.logoIconAdvantageBrandICP{fill: #8cbab8;}
.logoIconSecondaryBrandICP{fill: #d65241;}
.logoIconNeutral1BrandICP{fill: #6d6e6f;}
.logoIconNeutral2BrandICP{fill: #c2c4c6;}
.successIllBrandICP{fill:#2E8B85;}
.reviewClaimPrimaryICP{fill:#2E8B85;}
.reviewClaimAccent1ICP{fill:#8CBAB8;}
.reviewClaimAccent2ICP{fill:#8CBAB8;}
.cancelIllPrimaryICP{fill:#2E8B85;}
.cancelAccent1ICP{fill:#8CBAB8;}
.cancelAccent2ICP{fill:#8CBAB8;}
.cancelAlertRedICP{fill:#D01B00;}
.checkboxPrimaryICP{fill:#2E8B85;}
.checkboxAccentICP{fill:#D65241;}
.checkboxBorderICP{fill:#DCDCDC;}
.graphPrimaryClipICP{fill-rule:evenodd;clip-rule:evenodd;fill:#2E8B85;}
.graphPrimaryICP{fill:#2E8B85;}
.graphAccentICP{fill:#D65241;}

/* Home Depot specific SVG styles */
.logoPrimaryBrandHD{fill:#f26722;}
.logoHD{fill:#fff;}
.clockNeutral3HD{fill:#dcdcdc;}
.clockOpacityHD{opacity:100;}
.clockBackgroundHD{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.clockBorderHD{fill:#dcdcdc;}
.DealerPrimaryHD{fill:#f26722;}
.dollarBackgroundHD{fill:#E7E7E7;}
.dollarOutlineHD{fill:#F26722;}
.circleOutlineHD{fill:#F26722;}
.emailPrimaryBrandHD{fill:#F26722;}
.emailNeutral4HD{fill:#B1B1B1;}
.emailNeutral4HD{fill:#B1B1B1;}
.emailGreenCheckBkgHD{fill:#4ea54f;}
.enrollmentPrimaryHD{fill:#F26722;}
.enrollmentGreenCheckBkgHD{fill:#13aa41;}
.exclamationPrimaryBrandHD{fill:#F26722;}
.exclamationNeutral4HD{fill:#B1B1B1;}
.graphPrimaryHD{fill:#f26722;}
.graphPrimaryHD{fill:#f26722;}
.graphSecondaryHD{fill:#66686d;}
.revenueSecondaryHD{fill:#66686d;}
.revenuePrimaryHD{fill:#F26722;}
.logoiconPrimaryBrandHD{fill:#f26722;}
.logoiconHD{fill:#fff;}
.lockPrimaryHD{fill:#F26722;}
.lockSecondaryHD{fill:#66686D;}
.loginPrimaryHD{fill:#f26722;}
.ratingsPrimaryHD{fill:#F26722;}
.ratingsSecondaryHD{fill:#66686D;}
.peoplePrimaryHD{fill:#F26722;}
.peopleSecondaryHD{fill:#66686d;}
.profileAlertNeutral4HD{fill:#B1B1B1;}
.profileAlertPrimaryBrandHD{fill:#F26722;}
.profileAlertNeutral4HD{fill:#B1B1B1;}
.profileAlertRedHD{fill:#d01b00;}
.profilePrimaryBrandHD{fill:#F26722;}
.profileNeutral4HD{fill:#B1B1B1;}
.profileNeutral4HD{fill:#B1B1B1;}
.ratingsPrmaryHD{fill:#f26722;}
.ratingsSecondaryHD{fill:#66686d;}
.reportsbackgroundHD{fill:#E7E7E7;}
.reportsClipHD{fill:none;}
.circleoutlineHD{fill:#F26722;}
.resourcesCircleBkgHD{fill:#E7E7E7;}
.resourcesClipHD{fill:none;}
.circleOutlineHD{fill:#F26722;}
.revenuePrimaryBrandHD{fill:#F26722;}
.revenueNeutral4HD{fill:#B1B1B1;}
.sheildNeutral3HD{fill:#dcdcdc;}
.shieldCheckPrimaryHD{fill:#F26722;}
.shieldCheckSecondaryBrandHD{fill:#66686D;}
.successPrimaryBrandHD{fill:#F26722;}
.successNeutral4HD{fill:#B1B1B1;}
.successNeutral4HD{fill:#B1B1B1;}
.successGreenCheckBkgHD{fill:#4ea54f;}
.successTransparentHD{fill:none;}
.successPrimaryBrandHD{fill:#F26722;}
.successGreenCheckBkgHD{fill:#4ea54f;}
.circleBkgHD{fill:#E7E7E7;}
.circleToolsClipHD{fill:none;}
.circleOutlineHD{fill:#F26722;}
.uploadNeutral2HD{fill:#E7E7E7;}
.uploadPrimaryHD{fill:#F26722;}
.shieldNeutral3HD{fill:#dcdcdc;}
.shieldBkgHD{opacity:0.65;fill-rule:evenodd;clip-rule:evenodd;fill:none;}
.shieldBorderHD{opacity:100;fill-rule:evenodd;clip-rule:evenodd;fill:#dcdcdc;}
.LogoPrimaryHD{fill:#f26722;}