.wrapper {
    position: relative;
    max-width: 250px;
}

.optionsContent {
    position: absolute;
    top: 40px;
    left: 0px;
    background: white;
    z-index: 1;
    border: 1px solid #dee2e6;
    width: 100%;
    max-height: 228px;
    overflow-y: scroll;
}

.dropOption {
    padding: 8px 8px; 
    cursor: pointer;
}

.searchInput {
    border: none;
    line-height: inherit;
    padding: 4px 8px;
}

.optionsContent label,
.searchInput {
    width: 100%;
}

.optionsContent > * {
    border-bottom: 1px solid #dee2e6;
}

.optionsContent > .dropOption:last-child {
    border-bottom: none;
}

@media (max-width: 768px) {
    .wrapper {
        max-width: 100%;
    }
}